<template>
  <!-- eslint-disable -->
  <svg id="Layer_2" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g>
      <path d="M16,31.5c8.54999,0,15.5-6.95001,15.5-15.5S24.54999,0.5,16,0.5S0.5,7.45001,0.5,16S7.45001,31.5,16,31.5z M14.5,6   c0-0.83002,0.66998-1.5,1.5-1.5c6.34003,0,11.5,5.15997,11.5,11.5S22.34003,27.5,16,27.5c-0.83002,0-1.5-0.66998-1.5-1.5V6z"/>
      <path d="M24.5,16c0-4.17999-3.03003-7.65997-7-8.37V24.37C21.46997,23.65997,24.5,20.17999,24.5,16z"/>
    </g></svg>
  <!-- eslint-enable -->
</template>

<script>
  export default {
    name: 'ContrastIcon',
  };
</script>
