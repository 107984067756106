/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import { settings } from './modules/settings';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export const store = new Vuex.Store({
  modules: {
    settings,
  },
  plugins: [vuexLocal.plugin],
});
