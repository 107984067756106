<template>
  <div class="the-header">
    <img
      v-if="highContrastEnabled"
      class="the-header__sesja the-header__sesja--light"
      src="../../public/logo_white.png" alt="Sesja.pl"
    />
    <img v-else class="the-header__sesja" src="../../public/sesja.png" alt="Sesja.pl" />
    <img class="the-header__logo" :src="logoUrl" alt="logo" />
    <div class="wcag-wrapper">
      <button
        :class="highContrastEnabled ? 'wcag-button wcag-button--active' : 'wcag-button'"
        @click="toggleContrast"
      >
        <contrast-icon />
      </button>
      <button
        :class="lineHeightEnabled ? 'wcag-button wcag-button--active' : 'wcag-button'"
        @click="toggleLetterSpacing"
      >
        <line-height-icon />
      </button>
      <button
        :class="fontSize === 16 ? 'wcag-button wcag-button--active' : 'wcag-button'"
        @click="changeFontSize(16)"
      >
        A
      </button>
      <button
        :class="fontSize === 19 ? 'wcag-button big wcag-button--active' : 'wcag-button big'"
        @click="changeFontSize(19)"
      >
        A
      </button>
      <button
        :class="fontSize === 21 ? 'wcag-button huge wcag-button--active' : 'wcag-button huge'"
        @click="changeFontSize(21)"
      >
        A
      </button>
    </div>
    <div class="search-wrapper">
      <input v-model="query" @keyup.enter="search">
      <button @click="search">
        <search-icon />
      </button>
    </div>
  </div>
</template>

<script>
  import ContrastIcon from '@/components/ContrastIcon';
  import LineHeightIcon from '@/components/LineHeightIcon';
  import SearchIcon from '@/components/SearchIcon';

  export default {
    name: 'TheHeader',
    components: { SearchIcon, ContrastIcon, LineHeightIcon },
    props: {
      fontSize: Number,
      highContrastEnabled: Boolean,
      lineHeightEnabled: Boolean,
    },
    data() {
      return {
        query: '',
      };
    },
    computed: {
      logoUrl() {
        const clientName = window.location.hostname.split('.')[0];
        return `https://stor-01.sesja.pl/${clientName}-public/logo.png`;
      },
    },
    methods: {
      toggleContrast() {
        this.$emit('toggle-contrast', !this.highContrastEnabled);
      },
      toggleLetterSpacing() {
        this.$emit('toggle-line-height', !this.lineHeightEnabled);
      },
      changeFontSize(size) {
        this.$emit('change-fontsize', size);
      },
      search() {
        this.$router.push({ name: 'search', query: { q: this.query } });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .the-header {
    position: relative;
    padding: 16px 0;
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 5px solid #b61f1f;
    background: #ffffff;

    @at-root .high-contrast & {
      background: black;
      border-color: yellow;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &__sesja {
      position: absolute;
      top: 30px;
      left: 30px;
      width: 130px;
      height: auto;

      @media (max-width: 767px) {
        position: static;
      }

      &--light {
        top: 40px;
      }
    }

    &__logo {
      height: 100px;

      @media (max-width: 767px) {
        height: auto;
      }
    }

    .search-wrapper {
      position: absolute;
      right: 40px;
      bottom: -6px;
      max-width: 190px;
      display: flex;
      align-items: center;
      input {
        width: 100%;
      }
      button {
        border: none;
        outline: none;
        padding: 5px;
        font-weight: bold;
        margin: 3px;
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0);
        @at-root .high-contrast & {
          svg {
            fill: yellow;
          }
        }
      }
    }
    .wcag-wrapper {
      position: absolute;
      right: 50px;
      top: 40px;
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        position: static;
        margin-top: 10px;
      }

      .wcag-button {
        font-size: 12px;
        color: #333333;
        border: none;
        outline: none;
        padding: 5px;
        font-weight: bold;
        margin: 3px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @at-root .high-contrast & {
          background-color: black;
          border: 2px solid yellow;
          color: yellow;
          svg {
            fill: yellow;
          }
        }
        &--active,
        &:hover {
          background-color: $color--red;
          color: white;

          svg {
            fill: white;
          }

          @at-root .high-contrast & {
            background-color: yellow;
            border: 2px solid yellow;
            color: black;
            svg {
              fill: black;
            }
          }
        }
      }

      .big {
        font-size: 15px;
      }

      .huge {
        font-size: 19px;
      }
    }
  }
</style>
