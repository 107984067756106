   export const loadView = view => () => import(`@/views/${view}.vue`);

const routes = [
  {
    path: '/',
    name: 'home',
    component: loadView('Home'),
  },
  {
    path: '/live',
    name: 'live',
    component: loadView('Live'),
    meta: {
      pageTitle: 'Transmisja online',
    },
  },
  {
    path: '/videos',
    name: 'videos',
    component: loadView('Videos'),
    meta: {
      pageTitle: 'Archiwum nagrań',
    },
  },
  {
    path: '/videos/:id',
    name: 'video',
    component: loadView('Video'),
  },
  {
    path: '/pages/:slug',
    name: 'page',
    component: loadView('Page'),
  },
  {
    path: '/rodo',
    name: 'rodo',
    component: loadView('RODO'),
    meta: {
      pageTitle: 'RODO',
    },
  },
  {
    path: '/site-map',
    name: 'site-map',
    component: loadView('SiteMap'),
    meta: {
      pageTitle: 'Mapa strony',
    },
  },
  {
    path: '/search',
    name: 'search',
    component: loadView('Search'),
    meta: {
      pageTitle: 'Wyszukiwarka',
    },
  },
];

export { routes };
