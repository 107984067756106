<template>
  <nav class="the-nav" role="navigation">
    <div class="the-nav__group">
      <div class="the-nav__title">NAWIGACJA</div>
      <router-link
        class="the-nav__item"
        :to="{ name: 'live' }"
        aria-label="Transmisja online"
        exact
      >
        Transmisja online
      </router-link>
      <router-link
        class="the-nav__item"
        :to="{ name: 'videos' }"
        aria-label="Archiwum nagrań"
        exact
      >
        Archiwum nagrań
      </router-link>
      <component
        v-for="page of pages"
        :is="page.external ? 'a' : 'router-link'"
        :key="page.id"
        class="the-nav__item"
        :href="page.external ? page.url : null"
        :to="page.external ? null : { name: 'page', params: { slug: page.url } }"
        :target="page.external ? '_blank' : '_self'"
        :aria-label="page.title"
        exact
      >
        {{ page.title }}
      </component>
      <router-link
        class="the-nav__item"
        :to="{ name: 'site-map' }"
        aria-label="Mapa strony"
        exact
      >
        Mapa strony
      </router-link>
    </div>
    <div class="the-nav__group">
      <div class="the-nav__title">OSTATNIE NAGRANIA</div>
      <router-link
        v-for="video in videos"
        :key="video.id"
        class="the-nav__item"
        :to="{ name: 'video', params: { id: video.id } }"
        exact
      >
        <div>
          {{ video.title }}
        </div>
        <div class="the-nav__item__date">
          Dodano dnia: {{ video.publishedAt }}
        </div>
      </router-link>
    </div>
    <div class="the-nav__group">
      <div class="the-nav__title">PANEL RADNEGO</div>
      <a :href="adminPanelUrl" class="the-nav__item">Zaloguj się</a>
    </div>
    <div class="the-nav__group" v-if="cadences.length > 1">
      <div class="the-nav__title">KADENCJE</div>
        <p
          v-for="cadence of cadences"
          :class="cadence.value === select ? 'the-nav__item the-nav__item__active' : 'the-nav__item'"
          :key="cadence.value"
          v-on:click="setCadence(cadence.value)"
        >{{ cadence.text }}</p>
    </div>
  </nav>
</template>

<script>
  import { PortalService } from '../utils/portal-service';

  export default {
    name: 'TheNav',
    data() {
      return {
        select: 'default',
        videos: null,
        pages: [],
        cadences: [],
      };
    },
    computed: {
      adminPanelUrl() {
        return `${window.location.origin}/panel/login`;
      },
    },
    created() {
      this.select = localStorage.getItem('cadence') ?? 'default';
      PortalService.getRecordings().then(res => {
        this.videos = res.data['hydra:member'].slice(0, 5);
      });

      PortalService.getPages().then(res => {
        this.pages = res.data['hydra:member'];
      });

      PortalService.getCadences().then(res => {
        this.cadences = res.data;
        this.cadences.unshift({ text: 'Aktualna', value: 'default' });
      });
    },
    methods: {
      setCadence(value) {
        localStorage.setItem('cadence', value);
        this.select = value;
        window.location.reload();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .the-nav {
    &__group {
      background: #ffffff;
      margin-bottom: 16px;
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.75);
      @at-root .high-contrast & {
        background: black;
        border: 1px solid white;
      }
    }

    &__title {
      background: #b61f1f;
      color: #ffffff;
      height: 44px;
      line-height: 44px;
      display: flex;
      justify-content: center;
      font-size: 1rem;
      @at-root .high-contrast & {
        background: black;
        color: yellow;
        border-bottom: 1px solid white;
      }
    }

    &__item {
      cursor: pointer;
      color: #333333;
      font-weight: normal;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 12px;
      padding-bottom: 8px;
      border-bottom: 1px solid #c8c8c8;
      font-size: 1rem;
      @at-root .high-contrast & {
        color: white;
        border-bottom: 1px solid white;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: #b61f1f;
        @at-root .high-contrast & {
          color: yellow;
        }
      }

      &__active {
        color: #b61f1f;
      }

      &__date {
        margin-top: 4px;
        color: #555555;
        @include fontSize(12px);
        font-weight: normal;
        @at-root .high-contrast & {
          color: white;
        }
      }
    }
  }
</style>
