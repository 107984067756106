import { axios } from './axios';

const API_URL = `${process.env.VUE_APP_API_URL}/v1`;

export const PortalService = {
  getHomepage: id => axios.get(`${API_URL}/portal/homepage/${id}`),
  getLive: id => axios.get(`${API_URL}/portal/live/${id}`),
  getRecordings: params => axios.get(`${API_URL}/portal/video-recordings`, { params }),
  getRecording: id => axios.get(`${API_URL}/portal/video-recordings/${id}`),
  updateVisitedCounter: (id, body) => axios.patch(`${API_URL}/portal/video-recordings/${id}/visited`, body),
  getSubtitlesUrl: id => `${API_URL}/vod/video-publications/${id}/subtitles?cadence=${localStorage.getItem('cadence')}`,
  getSettings: () => axios.get(`${API_URL}/portal/settings/_default_`),
  getPages: () => axios.get(`${API_URL}/portal/pages`),
  getPage: id => axios.get(`${API_URL}/portal/pages/${id}`),
  getHomePage: () => axios.get(`${API_URL}/portal/homepage/_default_`),
  getSearch: query => axios.get(`${API_URL}/portal/search?q=${query}`),
  getCadences: () => axios.get(`${API_URL}/portal/cadence`),
};

export const setPageTitle = title => {
  if (!title) { return; }

  document.title = title;
};
