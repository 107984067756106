import * as types from '../mutationTypes';

const state = {
  settings: null,
  highContrastEnabled: false,
  fontSize: 16, // 16 - normal, 19 - medium, 21 - large //
  lineHeightEnabled: false,
};

const actions = {
  setSettings({ commit }, payload) {
    commit(types.SET_SETTINGS, payload);
  },
  setContrast({ commit }, payload) {
    commit(types.SET_CONTRAST, payload);
  },
  setFontSize({ commit }, payload) {
    commit(types.SET_FONT_SIZE, payload);
  },
  setLineHeight({ commit }, payload) {
    commit(types.SET_LINE_HEIGHT, payload);
  },
};

const mutations = {
  [types.SET_SETTINGS](state, payload) {
    state.settings = payload;
  },
  [types.SET_CONTRAST](state, payload) {
    state.highContrastEnabled = payload;
  },
  [types.SET_FONT_SIZE](state, payload) {
    state.fontSize = payload;
  },
  [types.SET_LINE_HEIGHT](state, payload) {
    state.lineHeightEnabled = payload;
  },
};

export const settings = {
  namespaced: true,
  state,
  actions,
  mutations,
};
