import axios from 'axios';

const http = axios.create();

http.interceptors.request.use(request => {
  if (!request.url.startsWith(process.env.VUE_APP_API_URL)) {
    return request;
  }

  // eslint-disable-next-line no-param-reassign
  if (request.params === undefined) {
    // eslint-disable-next-line no-param-reassign
    request.params = { cadence: localStorage.getItem('cadence') };
  } else {
    // eslint-disable-next-line no-param-reassign
    request.params.cadence = localStorage.getItem('cadence');
  }

  return request;
});

export { http as axios };
