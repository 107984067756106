<template>
  <div class="app">
    <the-header
      @toggle-contrast="toggleHighContrast"
      @change-fontsize="changeFontSize"
      @toggle-line-height="toggleLineHeight"
      :fontSize="fontSize"
      :highContrastEnabled="highContrastEnabled"
      :line-height-enabled="lineHeightEnabled"
    />
    <main class="container">
      <div class="columns">
        <div class="column is-one-quarter">
          <the-nav />
        </div>
        <div class="column wrapper">
          <router-view />
        </div>
      </div>
    </main>
    <v-dialog width="900px"/>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { PortalService } from '@/utils/portal-service';
  import TheHeader from './components/TheHeader';
  import TheNav from './components/TheNav';

  export default {
    components: { TheNav, TheHeader },
    computed: {
      ...mapState('settings', ['fontSize', 'highContrastEnabled', 'lineHeightEnabled']),
    },
    async created() {
      const res = await PortalService.getSettings();
      this.setSettings(res.data.settings);
      this.enableOuterScript(res.data.settings);
      this.changeFontSize(this.fontSize);
      this.toggleHighContrast(this.highContrastEnabled);
      this.toggleLineHeight(this.lineHeightEnabled);
    },
    methods: {
      ...mapActions('settings', ['setSettings', 'setContrast', 'setFontSize', 'setLineHeight']),

      enableOuterScript(settings) {
        if (settings && settings.portal_google_tag && settings.portal_google_tag.length) {
          const scriptValue = document.createTextNode(
            `
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

            ga('create', '${settings.portal_google_tag}', 'auto');
            ga('send', 'pageview');
          `,
          );

          const scriptToAdd = document.createElement('script');
          scriptToAdd.type = 'text/javascript';

          scriptToAdd.appendChild(scriptValue);
          document.head.appendChild(scriptToAdd);
        }
      },

      toggleHighContrast(highContrast) {
        const el = document.body;

        if (highContrast) {
          el.classList.add('high-contrast');
        } else {
          el.classList.remove('high-contrast');
        }

        this.setContrast(highContrast);
      },

      toggleLineHeight(enabled) {
        const el = document.body;

        if (enabled) {
          el.classList.add('wcag-line-height');
        } else {
          el.classList.remove('wcag-line-height');
        }

        this.setLineHeight(enabled);
      },

      changeFontSize(size) {
        const el = document.documentElement;
        el.style.fontSize = `${size}px`;
        this.setFontSize(size);
      },
    },
  };
</script>

<style lang="scss">

  $color: '#fff';

  html {
    height: 100vh;
  }

  body {
    min-height: 100vh;
    margin: 0;
    background: #f2f2f2;
    @include fontSize(16px);
    line-height: 1.5;

    &.high-contrast {
      background: black;
    }

    &.wcag-line-height {
      line-height: 200%;
    }
  }

  .app {
    min-height: 100vh;
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.75);
  }

  .wrapper {
    margin: 11px;
    background: #ffffff;
    @at-root .high-contrast & {
      background: black;
      color: white;
      border: 1px solid white;
      h1, h2, h3, h4, h5, h6 {
        color: yellow;
      }
    }
  }
  a {
    outline: none;
  }
  *:focus-visible {
    box-shadow: 0 0 10px rgba(0, 0, 0, .55);
    border-radius: 5px;
    @at-root .high-contrast & {
      border: 2px dashed yellow !important;
    }
  }
  input {
    border: none;
    border-bottom: 1px solid #b61f1f;

    @at-root .high-contrast & {
      border: none;
      border-bottom: 1px solid yellow;
      background: black;
      color: white;
    }
    &:focus {
      outline: none;
      box-shadow: none;
      border-bottom: 1px solid #b61f1f;
    }
  }
</style>
