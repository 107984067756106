import Vue from 'vue';
import Router from 'vue-router';

import { routes } from './routes';

Vue.use(Router);

const router = new Router({
  base: process.env.VUE_APP_BASEPATH,
  mode: 'history',
  routes,
});

router.afterEach(to => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.pageTitle ? `Portal | ${to.meta.pageTitle}` : 'Portal';
  });
});

export { router };
