import Vue from 'vue';
import 'video.js/dist/video-js.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import VueMeta from 'vue-meta';
import VModal from 'vue-js-modal';
import App from './App.vue';
import { router } from './router/router';
import { store } from './store/store';

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(VModal, { dialog: true });

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
